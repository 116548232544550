#root {
  height: 100%;
}

.main-outlet {
  display: grid;
  height: 100%;
}

.main-outlet__container {
  display: grid;
  grid-template-columns: 100%;
  background: linear-gradient(42.4deg, #5538d3 0%, #863ab5 45%, #482dbd 100%);
}

.route-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.KVButton {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #7550c2;
  font-family: Hero, sans-serif;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
