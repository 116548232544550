@font-face {
  font-family: 'Hero';
  src: url('assets/fonts/Hero-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Hero Light';
  src: url('assets/fonts/Hero-Light.ttf') format('truetype');
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: 1rem;
  height: 100%;
  box-sizing: border-box;
}

body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Hero', sans-serif;
}
